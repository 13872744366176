import { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import Tag from '../shared/Tag'

import { httpPost } from '@lib/utils'
import { remove } from 'lodash'


const InstanceTags = props => {
  const { tags, allTags, instanceId } = props

  const [selectedTag, setTag] = useState('')
  const [tagCloud, setTagCloud] = useState(tags)
  const [showAdd, setShowAdd] = useState(false)

  const removeTag = tag => {
    let clouds = [...tagCloud]
    remove(clouds, t => t.id == tag.id)

    setTagCloud(clouds)
  }

  const onSelect = async item => {
    setTag(item)

    const updates = { tagId: item.tag.id }
    const request = await httpPost(`/instances/${instanceId}/add_tag.json`, updates)

    if (request.status == 200) {
      setTagCloud([...tagCloud, item.tag])
      setTag(null)
    }
  }

  const usedIds = tagCloud.map(t => t.id)
  const options = allTags.filter(t => !usedIds.includes(t.id)).map(t => ({ label: t.displayName, tag: t }))

  return (
    <>
      <Group>
        <List>
          {tagCloud.map(tag => <Tag key={tag.id} tag={tag} removable instanceId={instanceId} removeTag={removeTag} />)}
        </List>
        <Value>
          {showAdd ? (
            <Group>
              <SelectWrap>
                <Select
                  options={options}
                  value={selectedTag}
                  onChange={onSelect} 
                  placeholder=""
                  menuPortalTarget={document.body} 
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
              </SelectWrap>
              <ShowAdd onClick={() => setShowAdd(false)}>
                <i className="fa fa-remove" />
              </ShowAdd>
            </Group>
          ) : (
            <ShowAdd onClick={() => setShowAdd(true)}>
              <i className="fa fa-plus" />
            </ShowAdd>
          )}
        </Value>
      </Group>
    </>
  )
}

export default InstanceTags

const Group = styled.div`
  margin-left: 1rem;  
  display: flex;
  flex-direction: row;
  gap: 1rem;
  min-height: 3em;
`

const List = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: center;
  gap: 1rem;
  cursor: default;
`

const Value = styled.div`
  display: block;
  margin-right: 1.5rem;
`

const SelectWrap = styled.div`
  display: block;
  width: 15em;
`

const ShowAdd = styled.div`
  display: block;
  border: 1px white dashed;
  border-radius: 0.5rem;
  height: 3em;
  line-height: 3em;
  text-align: center;
  width: 3em;
  color: #ccc;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #666;
    border: 1px #3DB6F2 dashed;
  }
`