import styled from 'styled-components'
import dayjs from 'dayjs'
const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

import { groupBy, map, sortBy, each } from 'lodash'

import { PRO_USERS } from '@lib/enumerations'

const InstancesColumn = ({ title, data, dataType }) => {
  let previousDate = dayjs("1990-12-01")

  const priorityMark = data => {
    if (dataType == 'ordered') {
      if ([2, 5, 6, 7, 8, 9, 11].includes(data.client?.order?.tariff?.id)) {
        return <OrangePoint />
      }
    } else {
      if (data.client?.issue?.priorityId == 4) {
        return <RedPoint />
      } else if (data.client?.issue?.priorityId == 3) {
        return <OrangePoint />
      } else if (data.client?.issue?.priorityId == 1) {
        return <YellowPoint />
      }
    }
  }

  const renderInstances = data => {
    return data.map(instance => {
      const date = dayjs(dataType == 'ordered' ? instance.client.order.createdAt : instance.created_at)
      const dateFormatted = date.format("D. M.")
      let dateVisible = ''
      let delimiter = ''

      if (dateFormatted !== previousDate.format("D. M.")) {
        dateVisible = dateFormatted

        if (previousDate.week() != date.week() && dataType != 'ordered') {
          delimiter = <Delimiter />
        }

        previousDate = date
      }

      return (
        <div key={instance.id}>
          {delimiter}
          <Instance>
            <DatePill>{dateVisible}</DatePill>
            <a
              href={`https://app.projektove.cz/tasks/${instance.client?.crm_client_card_id}`}
              target="_blank"
            >
              {instance.name}
            </a>
            {priorityMark(instance)}
            <a className="btn btn-default crm" href={`/instances/${instance.id}`}><i className="fa fa-eye" /></a>
            <a className="btn btn-default mobile" href={`tel:${instance.client?.phone}`}><i className="fa fa-phone" /></a>
          </Instance>
        </div>
      )
    })
  }
  const grouped = groupBy(data, i => {
    if (i.client && i.client?.issue && i.client?.issue.assignedToId) {
     return i.client?.issue.assignedToId
    } else {
      return 0
    }
  })

  const sortedUsers = sortBy(map(PRO_USERS, u => u), 'position')

  let userGroupsWithData = []
  each(sortedUsers, user => {
    if (grouped[user.id] && grouped[user.id].length > 0) {
      userGroupsWithData.push(
        <div key={user.id}>
          <DelimiterLarge />
          <Assignee>{PRO_USERS[user.id]?.name}</Assignee>
          {renderInstances(grouped[user.id] || [])}
        </div>
      )
    }
  })

  return (
    <Col>
      <Title>{title}</Title>

      <div>
        {userGroupsWithData}
      </div>

    </Col>
  )
}

export default InstancesColumn

const Col = styled.div`
  min-width: 20rem;
  background-color: #f5f5f5;
  padding-bottom: 0.5rem;
`

const Title = styled.div`
  padding: 0 1rem;
  font-weight: bold;
  text-align: center;
`

const Assignee = styled.div`
  padding: 0 1rem;
  font-weight: bold;
`

const Instance = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
  align-items: center;

  a {
    color: #666;
    flex: 1;
  }

  a.mobile {
    display: none;
  }

  a.crm {
    max-width: 1.5em;
    padding: 0 0.2em;
    font-size: 0.8em;
  }

  @media only screen and (max-width: 480px) {
    a.mobile {
      display: inline-block;
      width: 2em;
      height: 2em;
      flex: 0;
      text-align: center;
      padding: 0.2em 0.5em;
      margin: 0.5rem 0;
    }

    a.crm {
      display: none;
    }
  }

`

const DatePill = styled.div`
  font-size: 11px;
  min-width: 3rem;
`

const Delimiter = styled.div`
  background-color: white;
  height: 4px;
  width: 100%;
  margin: 4px 0 8px 0;
`

const DelimiterLarge = styled.div`
  background-color: white;
  height: 1rem;
  width: 100%;
  margin: 0.5rem 0 1rem 0;
`

const OrangePoint = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #e67f22;
`

const RedPoint = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #c20b19;
`

const YellowPoint = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #fee100;
`

