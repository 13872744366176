import { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import Tag from '../shared/Tag'

import { httpGet } from '@lib/utils'

const QuickSearch = props => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])

  const search = async query => {
    const result = await httpGet(`/search/quick?q=${query}`)

    setResults(result.instances)
  }

  const onChangeInput = ev => {
    const { value } = ev.target

    setQuery(value)

    if (value.length > 2) {
      search(value)
    }

    if (value == '') {
      clear()
    }
  }

  const clear = () => {
    setQuery('')
    setResults([])
  }

  return (
    <>
      <Search>
        <input value={query} onChange={onChangeInput} />

        {query != '' && <Clear onClick={clear}><i className="fas fa-remove" /></Clear>}
        {results.length > 0 && (
          <Results>
            {results.map(instance => (
              <Result href={`/instances/${instance.id}`}>
                {instance.name}
              </Result>
            ))}
          </Results>
        )}
      </Search>
    </>
  )
}

export default QuickSearch

const Search = styled.div`
  position: relative;
  display: flex;
  height: 5em;
  align-items: center;
  
  input {
    height: 2em;
    border: 1px #ccc solid;
    border-radius: 100px;
    padding: 0 1em;
  }
`

const Clear = styled.span`
  position: absolute;
  right: 0;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`
const Results = styled.div`
  position: absolute;
  z-index: 10;
  top: 3.5em;
  background-color: #fff;
  border: 1px #eee solid;
  margin: 0 1em;
  border-radius: 0.5em;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
`

const Result = styled.a`
  display: block;
  height: 3em;
  line-height: 3em;
  padding: 0 0.5em;
  min-width: 10em;
  max-width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;

  &:hover {
    background-color: #eee;
  }
`
