import styled from 'styled-components'

const Loading = props => {
  if (props.spinner) {
    return (
      <Wrap>
        <i className="fa fa-spinner fa-spin" />
      </Wrap>
    )
  }

  return <div>načítám...</div>
}

export default Loading

const Wrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`
