/** @format */

export const httpGet = url =>
  fetch(url, { headers: buildHeaders() }).then(checkStatus).then(parseJSON)

export const httpPost = (url, data, formData = null) => {
  const body = formData || JSON.stringify(data)

  return fetch(url, { method: 'post', headers: buildHeaders(), body: body })
}

// Suspense integrations like Relay implement
// a contract like this to integrate with React.
// Real implementations can be significantly more complex.
// Don't copy-paste this into your project!
export function wrapPromise(promise) {
  let status = 'pending'
  let result
  let suspender = promise.then(
    r => {
      status = 'success'
      result = r
    },
    e => {
      status = 'error'
      result = e
    }
  )

  return {
    read() {
      if (status === 'pending') {
        throw suspender
      } else if (status === 'error') {
        throw result
      } else if (status === 'success') {
        return result
      }
    },
  }
}

/**
 * PRIVATE
 */

function buildHeaders() {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  return headers
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else if (response.status == 404) {
    return response
  } else {
    let error = new Error(response.statusText)

    error.response = response

    throw error
  }
}

function parseJSON(response) {
  return response.json().catch(() => response)
}

export function formatDate(date, options = { short: false}) {
  if (options.short) {
    return new Date(date).toLocaleDateString('cs-CZ', { month: 'numeric', day: 'numeric' })
  } else {
    return new Date(date).toLocaleDateString()
  }
}

export function formatDateForInput(date) {
  const d = new Date(date)
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const day = String(d.getDate()).padStart(2, '0')
  return `${d.getFullYear()}-${month}-${day}`
}

export function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min
}
