import { useState } from 'react'
import styled from 'styled-components'

import { formatDateForInput, httpPost } from '@lib/utils'
import { Loading } from '@components/shared'

const AddEvent = props => {
  const [saving, setSaving] = useState(false)
  const [selectedDate, setDate] = useState(formatDateForInput(Date.now()))
  const [hours, setHours] = useState(0)
  const [users, setUsers] = useState(0)

  const onChangeDate = ev => {
    setDate(ev.target.value)
  }

  const sendEvent = async type => {
    const save = confirm(`Zapsat?`)

    if (save) {
      setSaving(true)

      const event = {
        type,
        date: selectedDate,
        hours,
        users,
      }
      const request = await httpPost(`/instances/${props.instanceId}/create_event.json`, { event })

      if (request.status == 200) {
        setHours(0)
        setUsers(0)
        setDate(formatDateForInput(Date.now()))
        setSaving(false)
      }
    }
  }

  return (
    <Group>
      <input
        className="form-control datepicker"
        type="date"
        value={selectedDate}
        onChange={onChangeDate}
      />
      {saving ? (
        <Loading spinner />
      ) : (
        <>
          <span className="btn btn-primary" target="_blank" onClick={() => sendEvent('call')}>
            <i className="fa fa-phone" />
          </span>
          <span
            className="btn btn-primary"
            target="_blank"
            onClick={() => sendEvent('salesMeeting')}
          >
            <i className="fa fa-suitcase" />
          </span>
          <span className="btn btn-primary" target="_blank" onClick={() => sendEvent('proposal')}>
            <i className="fa fa-handshake" />
          </span>

          <input
            className="form-control hours"
            type="number"
            value={hours}
            onChange={ev => setHours(ev.target.value)}
          />
          <span className="btn btn-primary" target="_blank" onClick={() => sendEvent('soldHours')}>
            <i className="fa fa-graduation-cap" />
          </span>

          <input
            className="form-control users"
            type="number"
            value={users}
            onChange={ev => setUsers(ev.target.value)}
          />
          <span className="btn btn-primary" target="_blank" onClick={() => sendEvent('soldUsers')}>
            <i className="fa fa-users" />
          </span>
        </>
      )}
    </Group>
  )
}

export default AddEvent

const Group = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1em;
  min-height: 3em;
  margin-right: 1em;

  input.datepicker {
    max-width: 8em;
  }

  input.hours {
    max-width: 3em;
    text-align: center;
    margin-left: 1em;
  }

  input.users {
    max-width: 4em;
    text-align: center;
    margin-left: 1em;
  }
`
