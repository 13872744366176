import cn from 'classnames'
import { httpPost } from '@lib/utils'

const Tag = props => {
    const { tag } = props
    let styles = {}

    if (tag.color) {
        styles['color'] = tag.color
    }

    if (tag.bgColor) {
        styles['backgroundColor'] = tag.bgColor
    }

    const onRemove = async () => {
        const updates = { tagId: props.tag.id }
        const request = await httpPost(`/instances/${props.instanceId}/remove_tag.json`, updates)

        if (request.status == 200) {
            props.removeTag(tag)
        }
    }
    
    return (
        <span className={cn("Tag Tag--large", { "Tag--removable": props.removable} )} style={styles}>
            {tag.emoji && <span className="Tag__emoji">{tag.emoji}</span>}
            {tag.displayName}
            {props.removable && (
                <span className="Tag__remove" onClick={onRemove}>
                    <i className="fa fa-remove" />
                </span>
            )}
        </span>
    )
}

export default Tag
